import React from "react";

import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";

import { Navbar, Dropdown, DropdownButton, Button } from "react-bootstrap";
import { appInsights } from "./AppInsights";
import { sessionIdentifier } from "./sessionIdentifier";

const NavigationBar = () => {
    const { instance } = useMsal();
    return (
        <>
            <AuthenticatedTemplate>
                <DropdownButton variant="secondary" className="ml-auto" drop="left" title="Sign Out">
                    <Dropdown.Item as="button" onClick={() => {
                        const accounts = instance.getAllAccounts();
                        const logoutRequest = { account: instance.getAccountByHomeId(accounts[0].homeAccountId), postLogoutRedirectUri: "/", mainWindowRedirectUri: "/" };
                        instance.logoutPopup(logoutRequest);
                        }}>Sign out using Popup</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => {
                        const accounts = instance.getAllAccounts();
                        const logoutRequest = { account: instance.getAccountByHomeId(accounts[0].homeAccountId), postLogoutRedirectUri: "/" };
                        instance.logoutRedirect(logoutRequest);
                        }}>Sign out using Redirect</Dropdown.Item>
                </DropdownButton>
            </AuthenticatedTemplate>

        </>
    );
};

export const PageLayout = (props) => {
    return (
        <>
            <Navbar className='navbar'>
                <a className='title' href="/">Aderant Identity</a>
                <NavigationBar />
            </Navbar>
            <br />
            <h5><center id="title">Authentication Test Application</center></h5>
            <br />
            {props.children}
            <br />
        </>
    );
};

export const IdTokenClaims = (props) => {
    appInsights.trackEvent({ name: `${sessionIdentifier}-PropertiesRefreshed` }, { message: props.idTokenClaims })
    return (
        <div id="token-div">
            <p><strong>Home Tenant Id: </strong> {props.idTokenClaims.tid}</p>
            <p><strong>Issuer: </strong> {props.idTokenClaims.iss}</p>
            <p><strong>OID: </strong> {props.idTokenClaims.sub}</p>
            <p><strong>UPN: </strong> {props.idTokenClaims.username}</p>
            <p><strong>IDP: </strong> {props.idTokenClaims.idp || 'Local Account, Not an IDP integration'}</p>
            <p><strong>Authentication Time: </strong> {new Date(props.idTokenClaims.auth_time * 1000).toISOString()}</p>
            <p><strong>Initiated At: </strong> {new Date(props.idTokenClaims.iat * 1000).toISOString()}</p>
            <p><strong>Not Before: </strong> {new Date(props.idTokenClaims.nbf * 1000).toISOString()}</p>
            <p><strong>Token will refresh: </strong> {new Date(props.idTokenClaims.exp * 1000).toISOString()}</p>
            <p><strong>Session Identifier for tracking: </strong>{sessionIdentifier}</p>
            <p><Button onClick={() => {
                appInsights.trackEvent({ name: `${sessionIdentifier}-Refresh Button Clicked` });
                props.refresh();
            }}>Refresh</Button></p>
            <p><Button onClick={props.reauthenticate}>Force Reauthentication</Button></p>
            <p><strong>View Last Refreshed: </strong>{new Date(props.lastRefreshTime).toISOString()}</p>
        </div>
    );
}