import React, { useState } from "react";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { PageLayout, IdTokenClaims } from "./ui.jsx";
import { loginRequest, msalConfig } from "./authConfig";


import "./styles/App.css";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { appInsights, reactPlugin } from "./AppInsights.js";
import { sessionIdentifier } from "./sessionIdentifier.js";

const IdTokenContent = () => {
    const { accounts, instance } = useMsal();
    const [idTokenClaims, setIdTokenClaims] = useState(null);
    const [lastRefreshTime, setLastRefreshTime] = useState(0);
    const handleLogin = () => {
        instance.acquireTokenSilent({ account: accounts[0], forceRefresh: true, authority: msalConfig.authority, redirectUri: msalConfig.redirectUri })
            .then((result) => {
                accounts[0] = result.account;
                setIdTokenClaims(result.idTokenClaims);
                console.log(result);
            })
            .catch((error) => {
                appInsights.trackEvent({ name: `${sessionIdentifier}-error` }, { message: error });
                console.log(error)
            })
    }
    function GetIdTokenClaims() {
        instance.acquireTokenSilent({account: accounts[0]}).then((result) => {
            accounts[0] = result.account;
            setIdTokenClaims(result.idTokenClaims);
            setLastRefreshTime(new Date().getTime());
        }).catch((err) => {
            appInsights.trackEvent({ name: `${sessionIdentifier}-error` }, { message: err });
            console.log(err);
        })
        // setIdTokenClaims(accounts[0].idTokenClaims)
        // setLastRefreshTime(new Date().getTime());
    }

    return (
        <>
            <h5 className="card-title">{accounts[0].name} is signed in.</h5>
            {idTokenClaims ?
                <IdTokenClaims idTokenClaims={idTokenClaims} refresh={GetIdTokenClaims} lastRefreshTime={lastRefreshTime} reauthenticate={handleLogin} />
                :
                <Button variant="primary" onClick={GetIdTokenClaims}>View Claims</Button>
            }
        </>
    );
};

const MainContent = () => {
    const { instance } = useMsal();
    const handleLogin = () => {
        instance.loginPopup(loginRequest)
            .catch((error) => {
                appInsights.trackEvent({ name: `${sessionIdentifier}-error` }, { message: error });
                console.log(error)
            })
    }
    return (
        <div className="App">
            <AuthenticatedTemplate>
                <IdTokenContent />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5 className="card-title">Please sign-in to see your profile information.</h5>
                <DropdownButton variant="primary" className="ml-auto" drop="down" title="Sign In">
                    <Dropdown.Item as="button" onClick={handleLogin}>Sign in using Popup</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => instance.loginRedirect(loginRequest)}>Sign in using Redirect</Dropdown.Item>
                </DropdownButton>
            </UnauthenticatedTemplate>
        </div>
    );
};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be 
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication 
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the 
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export default function App({ msalInstance }) {
    return (

        <MsalProvider instance={msalInstance}>
            <AppInsightsContext.Provider value={reactPlugin}>
                <PageLayout>
                    <MainContent />
                </PageLayout>
            </AppInsightsContext.Provider>
        </MsalProvider>
    );
}
