import {ApplicationInsights} from '@microsoft/applicationinsights-web'
import {
  ReactPlugin,
  withAITracking,
} from '@microsoft/applicationinsights-react-js'
import {config} from './appConfig';

const reactPlugin = new ReactPlugin()
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: config.instrumentationKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: {history: 'test'},
    },
    loggingLevelConsole: 4
  },
})
ai.loadAppInsights()

ReactPlugin.prototype.trackEvent = function(event, customProperties) {
  this._analyticsPlugin.trackEvent(event, customProperties)
}

export default Component => withAITracking(reactPlugin, Component)
export const appInsights = ai.appInsights
export {reactPlugin}